<template>
<div class="purse-withdrawal-requests">
  <table class="table">
    <tr>
      <th>ID</th>
      <th>Пользователь</th>
      <th>Сумма запроса, $</th>
      <th>Комиссия, $</th>
      <th>Сумма вывода, $</th>
      <th>Система вывода</th>
      <th>Статус</th>
      <th></th>
    </tr>
    <tr v-for="item in items" v-bind:key="item.id">
      <td>{{item.id}}</td>
      <td>
        <router-link :to="{ name: 'User', params: { id: item.purse.user.id }}">{{item.purse.user.username}}</router-link>
      </td>
      <td>{{item.amount}}</td>
      <td>{{item.commission}}</td>
      <td>{{item.withdrawal}}</td>
      <td>{{item.paymentSystem}}</td>
      <td v-bind:class="statusClass(item.status)">{{item.status}}</td>
      <td>
        <b-button v-on:click="toggleRequestInfo(item.id)">Детали</b-button>
      </td>
    </tr>
  </table>

  <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      v-on:change="selectPage"
      aria-controls="purse-log-list"
  ></b-pagination>

  <b-modal ref="request-modal" size="lg">

    <PurseWithdrawalRequest v-bind:request-id="selectedRequestId"></PurseWithdrawalRequest>

    <template #modal-footer="{ cancel }">

      <b-button @click="cancel()">Закрыть</b-button>

    </template>

  </b-modal>

</div>
</template>

<script>
import PurseService from "../services/PurseService";
import PurseWithdrawalRequest from "./PurseWithdrawalRequest";

export default {
  name: "PurseWithdrawalRequests",
  components: {PurseWithdrawalRequest},
  props: {
    userId: {type: Number, default: null}
  },
  data(){
    return {
      currentPage: 1,
      rows: 0,
      perPage:1,
      items: [],
      selectedRequestId: null
    }
  },
  methods: {
    selectPage(page) {
      this.currentPage=page;
      this.loadData();
    },
    loadData() {
      var self = this;
      PurseService.getWithdrawalRequests(this.userId, self.currentPage-1)
      .then((data) => {
        self.items = data.content;

        self.currentPage = data.number+1;
        self.rows = data.totalElements;
        self.perPage = data.size;
      })
    },
    toggleRequestInfo(requestId) {
      this.selectedRequestId = requestId;
      this.$refs['request-modal'].toggle()
    },
    statusClass(status) {
      if (status === "NEW" || status === "ACCEPTED" || status === 'CONFIRMED') {
        return "text-primary";
      }
      if(status === "CANCELED") {
        return "text-danger";
      }
      if (status === "COMPLETED") {
        return "text-success";
      }
      return "";
    }
  },
  mounted() {
    this.loadData();
    this.$root.$on('bv::modal::hide', () => {
      this.loadData();
    })
  }
}
</script>
