<template>
<div class="purse-withdrawal-requests" v-if="null != item">
  <div class="row mb-2">
    <div class="col-6 text-center">
      <b>Пользователь:</b> {{item.purse.user.username}}
    </div>
    <div class="col-6 text-center">
      <b>Баланс</b> $ {{item.purse.balance}}
    </div>

  </div>
  <table class="table">
    <tr>
      <th>Заказ</th>
      <td>#{{item.id}}</td>
      <td><b>{{item.status}}</b></td>
    </tr>
    <tr>
      <th>Сумма запроса, $</th>
      <td>{{item.amount}}</td>
      <td></td>
    </tr>
    <tr>
      <th>Коммиссия</th>
      <td>$ {{item.commission}} </td>
      <td>{{item.commissionPercent}} %</td>
    </tr>
    <tr>
      <th>Сумма вывода, $</th>
      <td><span class="text-success">{{item.withdrawal}}</span></td>
      <td></td>
    </tr>
    <tr>
      <th>Система вывода</th>
      <td colspan="2">{{item.paymentSystem}}</td>
    </tr>
    <tr>
      <th>Кошелек</th>
      <td colspan="2">{{item.paymentSystemUid}}</td>
    </tr>
  </table>

  <div>
    <b-button v-if="item.status==='CONFIRMED'" variant="success" class="mr-4"
              v-on:click="executePurseAction('accept')">Принять</b-button>
    <b-button v-if="item.status==='ACCEPTED'" variant="success" class="mr-4"
              v-on:click="executePurseAction('complete')">Завершить</b-button>
    <b-button v-if="item.status==='NEW' || item.status==='ACCEPTED' || item.status==='CONFIRMED'" variant="danger" class="mr-4"
              v-on:click="executePurseAction('cancel')">Отменить</b-button>
  </div>
</div>
</template>

<script>
import PurseService from "../services/PurseService";

export default {
  name: "PurseWithdrawalRequest",
  props: {
    requestId: {type: Number, default: null}
  },
  data(){
    return {
      item: null
    }
  },
  methods: {
    loadData(){
      var self = this;
      PurseService.getWithdrawalRequest(this.requestId)
      .then((data) => {
        self.item = data;
      })
      .catch(() => {
        alert("Ошибка")
      })
    },
    executePurseAction(action) {
      PurseService.executePurseAction(this.requestId, action)
      .then((data) => {
        this.item = data
      })
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>
