<template>
<div class="purse-withdrawal-page">
  <div class="row">
    <div class="col-12">
      <h3>Заявки на вывод средств</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <PurseWithdrawalRequests></PurseWithdrawalRequests>
    </div>
  </div>

</div>
</template>

<script>
import PurseWithdrawalRequests from "../components/PurseWithdrawalRequests";
export default {
  name: "PurseWithdrawalPage",
  components: {PurseWithdrawalRequests}
}
</script>

<style scoped>

</style>